export const framed = true
export const partner = '4for4'
export const overridePerms = true
export const authed = false
export const customPresets = true

// CSS Overrides
export const overridePrimary = '#000'
export const overrideSecondary = '#e3241a'
export const overrideCheckboxColor = '#e3241a'
export const overrideButtonText = '#fff'
export const overrideBackground = null
export const overrideColor = "#e3241a"
export const overridePlayerText = "#e3241a"
export const overrideBackgroundSecondary = "e3241a"
// export const overrideTableHighlight = "#41464f"
export const overrideTableHighlight = "rgb(230, 247, 255)"
// TEMP remove
export const api_token = 'wuOOYr1ZInN5aG6LaoRtx4eFdZCsNAH6'
export const api_id = '207859'

export const allowCheatsheets = false