import {
  Row,
  Col,
  Radio,
  Popconfirm,
  Input,
  Tooltip,
  Upload,
  Checkbox,
  Button,
  Select
} from 'antd'
import {
  UploadOutlined,
  DownloadOutlined
} from '@ant-design/icons'

import SetPositionBaseExpModal from './modals/set-position-base-exp'
import { CPTDisplay } from '../utils/showdown'

import { getTeamImg } from '../utils/team-images-loader'

import styled from 'styled-components'
import { overridePrimary } from '../constants/partner'

const StyledSearch = styled.div`
  font-size: 18px;
  width: 90%

  input {
    height: 33px;
  }

  .ant-btn {
    height: 33px;
  }
`
const StyledSettingButton = styled(Button)`
  margin-right: 10px;

  @media (max-width: 991px) {
    margin-top: 10px;
  }
`
const StyledAnalysisButton = styled(Button)`
  border: 1px solid ${overridePrimary};
  color: ${overridePrimary};
  width: 100%;

  :hover {
    border: 1px solid ${overridePrimary} !important;
    color: ${overridePrimary} !important;
    font-weight: 600;
  }

  @media (max-width: 991px) {
    margin-top: 10px;
  }
`
const StyledHandbuildButton = styled(Button)`
  width: 100%;

  :hover {
    border: 1px solid ${overridePrimary} !important;
    color: ${overridePrimary} !important;
    font-weight: 600;
  }

  @media (max-width:1100px) {
    font-size: 12px;
  }
  @media (max-width:991px) {
    font-size: 14px;
  }
`

const StyledPositonRadio = styled(Radio.Group)`
  @media (max-width: 991px) {
    margin-top: 10px;
  }
`

const filterOption = (input, option) =>
  (option?.value ?? '').toLowerCase().includes(input.toLowerCase())

const PlayersTableFilterSection = ({
  showdown, 
  site,
  slate, 
  positions, 
  updatePosition, 
  uploadCustomInputs, 
  downloadTemplate, 
  clearPlayerData, 
  zeroExposures,
  selectedPosition,
  search,
  players,
  onExposureChange,
  baseExpVisible,
  setBaseExpVisible,
  hideZeroMaxExp,
  setHideZeroMaxExp,
  hideZeroProjPts,
  setHideZeroProjPts,
  sport,
  selectedTeam,
  setSelectedTeam,
  setAnalysisVisible,
  handbuildMode,
  setHandbuildMode,
  downloadProjections
}) => {

  // get options for team dropdown
  let teamOptions = {'all': {
    'label': 'All Teams',
    'value': ''
  }}

  if (positions) {
    players.forEach(p => {
      if (!teamOptions[p.TeamAbbrev]) {
        teamOptions[p.TeamAbbrev] = {
          'label': getTeamImg(sport, p.TeamAbbrev),
          'value': p.TeamAbbrev
        }
      }
    })
  }
  
  return (
    <Row>
        <Col lg={3}>
          <StyledSearch>
            <Input.Search
              placeholder="Name"
              onChange={(e) => search(e.target.value)}
              style={{ width: '100%' }}
            />
          </StyledSearch>
          {
            setSelectedTeam ? (
              <Select
                showSearch
                placeholder="Team"
                optionFilterProp="children"
                value={selectedTeam}
                onChange={setSelectedTeam}
                filterOption={filterOption}
                options={Object.keys(teamOptions).map(k => teamOptions[k])}
                style={{ maxWidth: '130px', width: '100%', marginTop: '10px'}}
              />
            ) : ''
          }
        </Col>
        <Col lg={6}>
          {
            positions ? (
              <Row>
                <Col lg={24}>
                  <StyledPositonRadio
                    onChange={(e) => {
                      if (updatePosition) {
                        updatePosition(e)
                    }}}
                    value={selectedPosition}
                  >
                    {
                      showdown ? (
                        <>
                          <Radio.Button value={''}>FLEX</Radio.Button>
                          <Radio.Button value={"CPT"}>{CPTDisplay[site]}</Radio.Button>
                        </>
                      ) : (
                        <>
                          <Radio.Button value={''}>ALL</Radio.Button>
                          {
                            positions && positions.map(p => {
                              return (
                                <Radio.Button value={p} key={`pt-fs-${p}`}>{p}</Radio.Button>
                              )
                            })
                          }
                        </>
                      )
                    }
                  </StyledPositonRadio>
                </Col>
              </Row>
            ) : ''
          }
          <Checkbox
            style={{
              marginTop: positions ? '20px' : '5px'
            }}
            checked={hideZeroMaxExp}
            onChange={(e) => {
              setHideZeroMaxExp(!hideZeroMaxExp)
            }}
          >
              Hide 0 Max Exp
          </Checkbox>
          <Checkbox
            style={{
              marginTop: positions ? '20px' : '5px'
            }}
            checked={hideZeroProjPts}
            onChange={(e) => {
              setHideZeroProjPts(!hideZeroProjPts)
            }}
          >
              Hide 0 Proj Pts
          </Checkbox>
        </Col>
        <Col lg={13}>
            <Tooltip
              title={'Exports projections for the slate'}
              mouseEnterDelay={0.7}
            >
            <StyledSettingButton onClick={downloadProjections}>
              <DownloadOutlined /> Projections
            </StyledSettingButton>
          </Tooltip>
          <Upload
            accept=".csv"
            showUploadList={false}
            beforeUpload={file => {
                const reader = new FileReader()

                reader.onload = e => {
                    const _csvRows = e.target.result.split(/\r?\n/)
                    const _csvCols = []
                    _csvRows.forEach(row => {
                      _csvCols.push(row.split(','))
                    })
                    uploadCustomInputs(_csvCols)
                }
                reader.readAsText(file)

                // Prevent upload
                return false;
            }}
          >
            <Tooltip
              title={'CSV Format: ID,Name,Position,ProjPts,ProjOwn,MinExp,MaxExp'}
              mouseEnterDelay={0.7}
              >
              <StyledSettingButton>
                <UploadOutlined /> Custom Proj
              </StyledSettingButton>
            </Tooltip>
          </Upload>
          <Tooltip
            title={'Exports player table as a CSV for you to work off of'}
            mouseEnterDelay={0.7}
            >
            <StyledSettingButton onClick={downloadTemplate}>
              <DownloadOutlined /> Players
            </StyledSettingButton>
          </Tooltip>
          <Popconfirm
            cancelText="No"
            okText="Yes"
            onClick={(e) => e.stopPropagation()}
            onCancel={(e) => e.stopPropagation()}
            onConfirm={
              (e) => {
                e.stopPropagation()
                clearPlayerData()
              }
            }
            title={`Permenantly clear all inputted data for the ${slate} slate?`}
          >
            <StyledSettingButton>Reset</StyledSettingButton>
          </Popconfirm>
          <Popconfirm
            cancelText="No"
            okText="Yes"
            onClick={(e) => e.stopPropagation()}
            onCancel={(e) => e.stopPropagation()}
            onConfirm={
              (e) => {
                e.stopPropagation()
                zeroExposures()
              }
            }
            title={`This sets default for exposures to 0. This will erase your existing inputted data if you are not logged in. Proceed?`}
          >
            <StyledSettingButton>Set Default Exp to 0</StyledSettingButton>
          </Popconfirm>
          <SetPositionBaseExpModal 
            players={players} 
            onExposureChange={onExposureChange} 
            visible={baseExpVisible} 
            setVisible={() => {setBaseExpVisible(!baseExpVisible)}} 
            sport={sport}
          />
          {
            showdown ? (
              ''
            ) : (
              <StyledSettingButton onClick={() => {setBaseExpVisible(true)}}>Set Global MaxExp</StyledSettingButton>
            )
          }
        </Col>
        <Col lg={2}>
            {
              setAnalysisVisible ? (
                <StyledAnalysisButton
                  onClick={() => {setAnalysisVisible(true)}}
                >Analysis</StyledAnalysisButton>
              ) : ''
            }
            <div style={{marginTop: setAnalysisVisible ? '10px' : '0px'}}>
              {
                handbuildMode ? (
                    <StyledAnalysisButton
                      onClick={() => {setHandbuildMode(false)}}
                    >Handbuild</StyledAnalysisButton>
                ) : (
                    <StyledHandbuildButton
                      onClick={() => {setHandbuildMode(true)}}
                    >Handbuild</StyledHandbuildButton>
                )
              }  
            </div>
             
        </Col>
      </Row>
  )
}

export default PlayersTableFilterSection