import { 
  rosterPositionShowdownNFL, 
  positionAllowanceClassicNFL,
  rosterPositionShowdownMLB,
  positionAllowanceClassicMLB,
  rosterPositionShowdownNBA,
  positionAllowanceClassicNBA,
} from './manual-lineups'

import { getFlexPositionsForSport } from './normalize-lineups'

const deepCopy = (i) => {
  return JSON.parse(JSON.stringify(i))
}

export const getAvailablePositionsFromLineup = (lineup, sport) => {
  const availablePositions = {}

  Object.keys(lineup).forEach(p => {
    // remove number from end of position
    const position = (p.substring(0, p.length - 1) + p.slice(-1).replace(/\d+/g, '')).toUpperCase()
    if (!lineup[p]) {
      if (position === 'FLEX' || position === 'UTIL') {
        const pos = getFlexPositionsForSport(sport)
        pos.forEach(p => {
          availablePositions[p] = true
        })
      } else {
        availablePositions[position] = true
      }
    }
  })

  return availablePositions
}

export function getPositionForPlayer(player, record, positions, showdown, sport, site) {
  // Add in missing fields and make sure that we have correct types
  let rp = rosterPositionShowdownNFL
  let pa = positionAllowanceClassicNFL[site]
  switch (sport) {
    case 'nfl':
      rp = rosterPositionShowdownNFL
      pa = positionAllowanceClassicNFL[site]
      break
    case 'mlb':
      rp = rosterPositionShowdownMLB
      pa = positionAllowanceClassicMLB[site]
      break
    case 'nba':
      rp = rosterPositionShowdownNBA
      pa = positionAllowanceClassicNBA[site]
      break
    default:
      break
  }

  let insertPos = null
  // fill all positions
  positions.forEach(pos => {
    // if we already set insertPos get out of loop
    if (insertPos) return 

    // if the player matches that position and it is missing in the record add it

    if (showdown) {
      if (player.RosterPosition && rp[player.RosterPosition].indexOf(pos) >= 0 && !record[pos]) {
        insertPos = pos
      }
    } else {
      player.Positions.forEach(playerPos => {
        if (insertPos) return 
        if (pa[playerPos.toLowerCase()].indexOf(pos) >= 0 && !record[pos]) {
          insertPos = pos
          return
        }
      })
    }
    return
  })

  return insertPos
}

export const getCurrentPositionlessLineup = (players, positions, showdown, sport, site) => {
  let errors = []
  const currentLineup = {}
  positions.forEach(pos => {
    currentLineup[pos] = null
  })

  let lockedPlayers = []
  players.forEach(p => {
    if (Number(p.MinExp) === 100) {
      lockedPlayers.push(p)
    }
  })

  if (positions.length < lockedPlayers.length) {
    errors.push("Too many locked players")
  }

  lockedPlayers.forEach((p, i) => {
    if (positions[i]) {
      currentLineup[positions[i]] = deepCopy(p)
    }
  })

  return [
    currentLineup,
    errors
  ]
}

export const getCurrentLineup = (players, positions, showdown, sport, site) => {
  const errors = []
  const currentLineup = {}
  positions.forEach(pos => {
    currentLineup[pos] = null
  })

  // get all locked players
  let lockedPlayers = []
  players.forEach(p => {
    if (Number(p.MinExp) === 100) {
      lockedPlayers.push(p)
    }
  })

  let remainingPlayers = []
  // first pass, lock in players with only one position
  lockedPlayers.forEach(p => {
    if (p.Positions.length === 1) {
      const rosterPos = getPositionForPlayer(p, currentLineup, positions, showdown, sport, site)
      if (!rosterPos) {
        errors.push(`There are too many players for position ${p.Position}`)
      } else {
        currentLineup[rosterPos] = deepCopy(p)
      }
    } else {
      remainingPlayers.push(p)
    }
  })

  // set it up to start on remaining players
  let nextPass = deepCopy(remainingPlayers)
  remainingPlayers = []
  // second pass eliminate filled positions from the remaining players
  while (nextPass.length !== remainingPlayers.length) {
    remainingPlayers = deepCopy(nextPass)
    nextPass = []

    remainingPlayers.forEach(p => {
      // eliminate positions that are filled
      const playerCopy = deepCopy(p)
      p.Positions.forEach(pos => {
        const positionsCopyPlayer = deepCopy(playerCopy)
        positionsCopyPlayer.Positions = [pos]
        const rosterPos = getPositionForPlayer(positionsCopyPlayer, currentLineup, positions, showdown, sport, site)
  
        if (!rosterPos) {
          const _i = p.Positions.indexOf(pos)
          playerCopy.Positions.splice(_i, 1)
        }
      })
  
      // there is no place to put this player
      if (playerCopy.Positions.length === 0) {
        console.log(2)
        errors.push(`No roster spot available for ${playerCopy.Name}`)
      } else if (playerCopy.Positions.length === 1) {
        // add player
        const rosterPos = getPositionForPlayer(playerCopy, currentLineup, positions, showdown, sport, site)
        if (!rosterPos) {
          errors.push(`No roster spot available for ${playerCopy.Name}`)
        } else {
          currentLineup[rosterPos] = playerCopy
        }
        
      } else {
        // save them for another pass if they can go into multiple slots
        nextPass.push(playerCopy)
      }
    })
  }

  let stuck = false
  // we still have players left.
  while (!stuck && nextPass.length > 0) {
    if (nextPass.length === 1) {
      const _p = nextPass[0]
      // if its just one left place them
      const rosterPos = getPositionForPlayer(_p, currentLineup, positions, showdown, sport, site)
      if (!rosterPos) {
        errors.push(`No roster spot available for ${_p.Name}`)
      } else {
        currentLineup[rosterPos] = _p
      }

      nextPass = []
    } else if (nextPass.length > 1) {
      let proceed = true
      const usedPlayers = {}
  
      // knock out players who have to go in certain spots
      while (proceed) {
        proceed = false
        // we are going to need to brute force them
        let _remainingPositions = getAvailablePositionsFromLineup(currentLineup, sport)
        const availabilities = {}
  
        Object.keys(_remainingPositions).forEach(rPos => {
          nextPass.forEach(p => {
            // skip if we have placed this player
            if (usedPlayers[p.Id]) return
  
            p.Positions.forEach(pPos => {
              if (pPos.toLowerCase() === rPos.toLowerCase()) {
                if (!availabilities[rPos]) availabilities[rPos] = []
  
                availabilities[rPos].push(p)
              }
            })
          })
        })
        
        // lock in position if no other player can take it
        Object.keys(_remainingPositions).forEach(rPos => {
          if (availabilities[rPos] && availabilities[rPos].length === 1) {
            const _p = deepCopy(availabilities[rPos][0])
            // skip player if seen before. this can happen if last player can be in 2 slots
            if (usedPlayers[_p.Id]) return

            // make sure this player goes into this roster slot
            _p.Positions = [rPos]
            const rosterPos = getPositionForPlayer(_p, currentLineup, positions, showdown, sport, site)
            if (!rosterPos) {
              errors.push(`No roster spot available for ${nextPassNew[0].Name}`)
            } else {
              currentLineup[rosterPos] = _p
            }
            // mark player as used
            usedPlayers[_p.Id] = true
            proceed = true
          }
        })
      }
      
      let nextPassNew = []
      nextPass.forEach(p => {
        if (!usedPlayers[p.Id]) nextPassNew.push(p)
      })

      if (nextPass.length === nextPassNew.length) {
        // just place a player and keep going
        const rosterPos = getPositionForPlayer(nextPassNew[0], currentLineup, positions, showdown, sport, site)
        if (!rosterPos) {
          errors.push(`No roster spot available for ${nextPassNew[0].Name}`)
        } else {
          currentLineup[rosterPos] = nextPassNew[0]
        }
        usedPlayers[nextPassNew[0].Id] = true
        
        nextPassNew = []
        nextPass.forEach(p => {
          if (!usedPlayers[p.Id]) nextPassNew.push(p)
        })

        if (nextPass.length === nextPassNew.length) {
          console.log("REAL doo doo")
          console.log(nextPass)
          console.log(usedPlayers)
          console.log(getAvailablePositionsFromLineup(currentLineup, sport))
          stuck = true
        }

        nextPass = nextPassNew
      } else {
        nextPass = nextPassNew
      }
    }
  }

  return [
    currentLineup,
    errors
  ]
}

export const mockOptLineup = (lineup, positions) => {
  let optLineup = []

  positions.forEach((k, i) => {
    const player = {...lineup[k]}
    player.ProjPtsFixed = player.ProjPts
    player.ProjOwnFixed = player.ProjOwn
    player.Run = i+1

    optLineup.push(player)
  })

  return optLineup
}