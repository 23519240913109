import { useEffect, useState } from 'react'

import { Button, Card, Row, Col, Table, Alert, Select } from 'antd'
import styled from 'styled-components'
import { overrideButtonText, overrideColor, overridePrimary, overrideBackgroundSecondary } from '../constants/partner'
import { CheckCircleFilled, CloseOutlined } from "@ant-design/icons"
import { getTeamImg } from "../utils/team-images-loader"
import { getPositionsClassic, getPositionsShowdown } from '../utils/normalize-lineups'
import { getCurrentLineup, getCurrentPositionlessLineup, mockOptLineup } from '../utils/handbuilding'

const BuildSection = styled.div`
  position: absolute;
  background-color: ${overrideBackgroundSecondary};
  height: 60px;
  width: 100%;
  bottom: 0px;
  left: 0px;
  border-radius: 10px;
`
const LineupInfoSection = styled.div`
  position: absolute;
  background-color: ${overridePrimary};
  height: 38px;
  width: 100%;
  top: 0px;
  left: 0px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
`
const StyledClearButton = styled(Button)`
  position: relative;
  top: 13px;
  background: transparent;
  border: 2px solid ${overrideButtonText};
  width: 90px;
  color: ${overrideButtonText || 'black'};
`
const StyledBucketButton = styled(Button)`
  position: relative;
  top: 13px;
  background: transparent;
  border: 2px solid ${overrideButtonText};
  width: 120px;
  color: ${overrideButtonText || 'black'};
`
const StyledBucketSelect = styled(Select)`
  position: relative;
  top: 13px;
  background: transparent;
  width: 100px;
  color: ${overrideButtonText || 'black'};
`
const ErrorSection = styled.div`
  margin-top: 25px;
`
const ErrorText = styled.span`
  color: red;
`

const HandbuildBox = ({removePlayer, clearAll, players, site='dk', sport='nfl', counter, season, slate, showdown, maxSalary, handbuildError, positionless=false, lineupRuns=[], addLineup, addLineupBatch}) => {
  const [selectedBucket, setSelectedBucket] = useState()
  const [showAdded, setShowAdded] = useState(false)
  
  const positions = showdown ? getPositionsShowdown(site, sport) : getPositionsClassic(site, sport)
  let currentLineup = {}
  let errors = []
  if (positionless) {
    [ currentLineup, errors ] = getCurrentPositionlessLineup(players, positions, showdown, sport, site)
  } else {
    [ currentLineup, errors ] = getCurrentLineup(players, positions, showdown, sport, site)
  }

  const lineupBuckets = lineupRuns.filter(lur => lur.bucket === true)

  let openPositions = 0
  let totalSal = 0
  let totalProj = 0
  let totalOwn = 0
  positions.forEach(p => {
    if (!currentLineup[p]) {
      openPositions += 1
    } else {
      totalSal += Number(currentLineup[p].Salary)
      totalProj += Number(currentLineup[p].ProjPts || 0)
      totalOwn += Number(currentLineup[p].ProjOwn || 0)
    }
  })

  const addToBucket = () => {
    const _lineup = mockOptLineup(currentLineup, positions)

    addLineupBatch({
      players: [_lineup],
      lineupRunId: selectedBucket,
      site: site,
      sport: sport,
      counter: counter,
      season: season,
      slate: slate,
    })

    setShowAdded(true)
    setTimeout(() => {
      setShowAdded(false)
    }, 500)
  }

  const isLineupComplete = () => {
    if (errors.length > 0) return false

    if (maxSalary - totalSal < 0) return false

    let complete = true
    Object.keys(currentLineup).forEach(k => {
      if (!currentLineup[k] || !currentLineup[k].Name) {
        complete = false
      }
    })

    return complete
  }

  return (
    <Card 
      bordered={true} 
      hoverable={true}
      style={{
        minHeight: '750px',
        borderRadius: '10px',
        overflowX: 'scroll'
      }}
    >
        <LineupInfoSection>
          <div style={{textAlign: 'center', fontSize: '14px', fontWeight: 600, marginTop: '8px', color: overrideButtonText}}>Handbuild</div>
        </LineupInfoSection>
        <Row style={{padding: '10px', color: overrideButtonText, marginTop: '20px'}}>
          <Col lg={12}>
            {
              maxSalary - totalSal < 0 ? (
                <ErrorText>Remaining Salary: ${maxSalary - totalSal}</ErrorText>
              ) : (
                <span style={{color: overridePrimary}}>Remaining Salary: ${maxSalary - totalSal}</span>
              )
            }
          </Col>
          <Col lg={12}>
            <span style={{color: maxSalary - totalSal < 0 ? 'red' : overridePrimary}}>Avg Remaining: ${((maxSalary - totalSal) / (openPositions || 1)).toFixed(0)}</span>
          </Col>
          <Col lg={12}>
            <span style={{color: overridePrimary}}>Total Pts: {totalProj.toFixed(1)}</span>
          </Col>
          <Col lg={12}>
            <span style={{color: overridePrimary}}>Total Own: {totalOwn.toFixed(1)}</span>
          </Col>
        </Row>

      <div style={{marginTop: "20px"}}>
        <SingleLineupTable 
          positions={positions}
          removePlayer={removePlayer}
          sport={sport}
          record={currentLineup}
          positionless={positionless}
        />
      </div>
      <ErrorSection>
        {
          handbuildError ? (
            <Alert type='error' message={handbuildError} />
          ) : ''
        }
      </ErrorSection>
      <div style={{textAlign: 'center'}}>
        
      </div>
      <BuildSection>
        <Row>
          <Col lg={6} md={24} sm={24} xs={24}>
            <StyledClearButton style={{marginLeft: '10px'}} onClick={() => {clearAll(currentLineup)}}>Clear</StyledClearButton>
          </Col>
          <Col lg={18} md={24} sm={24} xs={24}>
            <StyledBucketButton style={{marginLeft: '10px'}} onClick={() => {addToBucket()}} disabled={!selectedBucket || !isLineupComplete()} >{showAdded ? <CheckCircleFilled /> : 'Add to Bucket'}</StyledBucketButton>
            <StyledBucketSelect
              value={selectedBucket}
              onChange={v => {
                setSelectedBucket(v)
              }}
            >
              {
                lineupBuckets.map(lur => {
                  return (
                    <Select.Option key={lur.id} value={lur.id}>
                      {lur.name}
                    </Select.Option>
                  )
                })
              }
            </StyledBucketSelect>
          </Col>
        </Row>
      </BuildSection>
    </Card>
  )
}

const StyledTable = styled(Table)`
  td, th {
    font-size: 14px;
  }
`

const cols = (removePlayer, sport, positionless) => {
  const _c = [
    {
      title: '',
      key: 'remove',
      render: (_, row) => {
        if (!row.Name) return <div style={{minHeight: '20px'}}></div>
        return (
          <div style={{cursor: 'pointer', color: 'red', textAlign: 'center', minHeight: '20px'}}>
            <CloseOutlined onClick={() => {removePlayer(row)}} />
          </div>
        )
      }
    },
    {
      title: 'Pos.',
      dataIndex: 'keyPos',
      key: 'keyPos',
      render: (v) => {
        return v.toUpperCase()
      }
    },
    {
      title: 'Name',
      dataIndex: 'Name',
      key: 'name'
    },
    {
      title: 'Sal',
      dataIndex: 'Salary',
      key: 'salary',
      width: 60
    },
    {
      title: 'Pts',
      dataIndex: 'ProjPts',
      key: 'projpts',
      width: 60,
      render: (val, row) => {
        if (!row.Name) return ''
        return (Number(val || 0)).toFixed(1)
      }
    },
    {
      title: 'Own',
      dataIndex: 'ProjOwn',
      key: 'projown',
      width: 60,
      render: (val, row) => {
        if (!row.Name) return ''
        console.log(val)
        return (Number(val || 0)).toFixed(1)
      }
    },
  ]

  if (!positionless) {
    _c.push(
      {
        title: 'Tm',
        dataIndex: 'TeamAbbrev',
        key: 'teamAbbrev',
        width: 40,
        render: (text, row) => {
          if (text)
            return (
              getTeamImg(sport, text)
            )
        }
      }
    )
  }

  return _c
}

const SingleLineupTable = ({record, positions, removePlayer, sport, positionless}) => {
  const data = []
  positions.forEach((p, i) => {
    const _r = record[p] || {}
    data.push({
      ..._r,
      keyPos: p
    })
  })

  return (
    <StyledTable 
      columns={cols(removePlayer, sport, positionless)}
      dataSource={data}
      size="small"
      bordered={true}
      pagination={false}
      rowKey={(r) => r.Id}
    />
  )
}

export default HandbuildBox