import * as afterStartActions from '../actions/after-start'

const initialState = {
  afterStartLineups: {
    loading: false,
    data: {},
    failedLoading: false
  },
  activated: false
}

export default (state = initialState, action) => {
    switch (action.type) {
      case (afterStartActions.afterStartLineups.UPDATE):
        return {
          ...state,
          ...{
            afterStartLineups: {
              loading: false,
              data: action.payload,
              failedLoading: false
            }
          }
        }
      case (afterStartActions.afterStartLineups.ACTIVATE):
        return {
          ...state,
          activated: action.payload
        } 
      case (afterStartActions.afterStartLineups.TOGGLE):
        return {
          ...state,
        }
      case (afterStartActions.afterStartLineups.CLEAR):
        return {
          ...state,
          afterStartLineups: {
            loading: false,
            data: {},
            failedLoading: false
          },
          activated: false
        }
      default:
        return state
    }
}

export const getAfterStartLineups = state => state.afterStart.afterStartLineups.data
export const getAfterStartActivated = state => state.afterStart.activated