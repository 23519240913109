import { Modal, Checkbox, Input, Row, Col } from 'antd'
import React, { useState } from 'react'

const initalPositionUpdates = {
  nfl: {
    settings: {
      positionless: false,
      examplePos: 'QB'
    },
    positions: {
      QB: {
        MinExp: 0,
        MaxExp: 100,
        ProjPtsMin: 5,
      },
      RB: {
        MinExp: 0,
        MaxExp: 100,
        ProjPtsMin: 3,
      },
      WR: {
        MinExp: 0,
        MaxExp: 100,
        ProjPtsMin: 3,
      },
      TE: {
        MinExp: 0,
        MaxExp: 100,
        ProjPtsMin: 2,
      },
      DST: {
        MinExp: 0,
        MaxExp: 100,
        ProjPtsMin: 0,
      },
    }
  },
  nba: {
    settings: {
      positionless: false,
      examplePos: 'PG'
    },
    positions: {
      PG: {
        MinExp: 0,
        MaxExp: 100,
        ProjPtsMin: 3,
      },
      SG: {
        MinExp: 0,
        MaxExp: 100,
        ProjPtsMin: 3,
      },
      SF: {
        MinExp: 0,
        MaxExp: 100,
        ProjPtsMin: 3,
      },
      PF: {
        MinExp: 0,
        MaxExp: 100,
        ProjPtsMin: 3,
      },
      C: {
        MinExp: 0,
        MaxExp: 100,
        ProjPtsMin: 3,
      },
    }
  },
  mlb: {
    settings: {
      positionless: false,
      examplePos: 'OF'
    },
    positions: {
      P: {
        MinExp: 0,
        MaxExp: 100,
        ProjPtsMin: 5,
      },
      C: {
        MinExp: 0,
        MaxExp: 100,
        ProjPtsMin: 3,
      },
      '1B': {
        MinExp: 0,
        MaxExp: 100,
        ProjPtsMin: 3,
      },
      '2B': {
        MinExp: 0,
        MaxExp: 100,
        ProjPtsMin: 3,
      },
      'SS': {
        MinExp: 0,
        MaxExp: 100,
        ProjPtsMin: 3,
      },
      '3B': {
        MinExp: 0,
        MaxExp: 100,
        ProjPtsMin: 3,
      },
      'OF': {
        MinExp: 0,
        MaxExp: 100,
        ProjPtsMin: 3,
      },
    }
  },
  'pga': {
    settings: {
      positionless: true,
      examplePos: 'G'
    },
    positions: {
      'G': {
        MinExp: 0,
        MaxExp: 100,
        ProjPtsMin: 10
      }
    }    
  },
  'mma': {
    settings: {
      positionless: true,
      examplePos: 'F'
    },
    positions: {
      'F': {
        MinExp: 0,
        MaxExp: 100,
        ProjPtsMin: 10
      }
    }    
  },
  'nascar': {
    settings: {
      positionless: true,
      examplePos: 'D'
    },
    positions: {
      'D': {
        MinExp: 0,
        MaxExp: 100,
        ProjPtsMin: 10
      }
    }    
  },
}

const deepCopy = (obj) => {
  return JSON.parse(JSON.stringify(obj))
}

export default ({players, onExposureChange, visible, setVisible, sport}) => {
  if (!sport) return ''

  const [ positionUpdates, setPositionUpdates ] = useState(deepCopy(initalPositionUpdates[sport].positions))
  const [ override, setOverride ] = useState(false)

  return (
    <Modal
      title={`Set Base Exposures`}
      open={visible}
      onOk={()=>{
        const changedRows = []
        players.forEach((p) => {
          if (initalPositionUpdates[sport].settings.positionless) {
            // apply to full player table
            const _player = { ...p }
            
            // skip editted
            if (!override) {
              if (Number(p.MinExp) !== 0 || (Number(p.MaxExp) !== 0 && Number(p.MaxExp) !== 100)) {
                return
              }
            }

            // get the only pos that there is
            const soloPos = Object.keys(initalPositionUpdates[sport].positions)[0]
            if (
              Number(_player.ProjPts) >= Number(positionUpdates[soloPos].ProjPtsMin)
            ) {
              _player.MaxExp = positionUpdates[soloPos].MaxExp
            } else {
              _player.MinExp = 0
              _player.MaxExp = 0
            }

            changedRows.push(_player)

          } else {
            const _player = { ...p }
            const _Position = p.Position.split("/")[0]

            if (!override) {
              if (Number(p.MinExp) !== 0 || (Number(p.MaxExp) !== 0 && Number(p.MaxExp) !== 100)) {
                return
              }
            }

            if (
              Number(_player.ProjPts) >= Number(positionUpdates[_Position].ProjPtsMin)
            ) {
              _player.MaxExp = positionUpdates[_Position].MaxExp
            } else {
              _player.MinExp = 0
              _player.MaxExp = 0
            }

            changedRows.push(_player)
          }
        })

        onExposureChange(changedRows)
        setPositionUpdates(deepCopy(initalPositionUpdates[sport].positions))
        setOverride(false)
        setVisible(false)
      }}
      onCancel={() => {
        setVisible(false)
        setOverride(false)
        setPositionUpdates(deepCopy(initalPositionUpdates[sport].positions))
      }}
      width={'70%'}
      style={{top: '100px'}}
    >
      <h4>
        Change the default exposure for each position. By default this will ignore any player whose exposure has already been adjusted.
        <br/>
        <br/>
        <span style={{fontWeight: 400, marginTop: '15px'}}><i>E.g. <b>{initalPositionUpdates[sport].settings.examplePos} MaxExp=20</b> will set all {initalPositionUpdates[sport].settings.examplePos}s to have Max Exp of 20. <b>{initalPositionUpdates[sport].settings.examplePos} Proj Pts. Threshold=12.</b> will set all {initalPositionUpdates[sport].settings.examplePos}s under 12 Proj Pts to have Max Exp of 0.</i></span>
      </h4>
      <Row style={{margin: '40px 0px 40px 0px'}}>
        <Col lg={8}>
          <Checkbox onChange={(e) => {setOverride(e.target.checked)}} checked={override}>Override All Existing Exposures</Checkbox>
        </Col>
      </Row>
      <Row style={{fontWeight: 600, marginBottom: '10px'}}>
        <Col lg={4}>Position</Col>
        <Col lg={6}>
          MaxExp
        </Col>
        <Col lg={6}>
          Proj Pts. Threshold
        </Col>
      </Row>
      {
        Object.keys(initalPositionUpdates[sport].positions).map((position, i) => {
          return (
            <Row key={`position-base-exp-${i}`}>
              <Col lg={4}>{position}</Col>
              <Col lg={6}>
                <Input style={{width: '40%'}} value={positionUpdates[position] ? positionUpdates[position].MaxExp : 0} type="number" min={0} max={100} onChange={(e) => {
                  const _positionUpdates = { ...positionUpdates }
                  _positionUpdates[position].MaxExp = Number(e.target.value)
                  if (_positionUpdates[position].MaxExp > 100)
                    _positionUpdates[position].MaxExp = 100

                  setPositionUpdates(_positionUpdates)
                }}/>
              </Col>
              <Col lg={6}>
                <Input style={{width: '40%'}} value={positionUpdates[position] ? positionUpdates[position].ProjPtsMin : 0} type="number" min={0} max={100} onChange={(e) => {
                  const _positionUpdates = { ...positionUpdates }
                  _positionUpdates[position].ProjPtsMin = Number(e.target.value)
                  if (_positionUpdates[position].ProjPtsMin < 0)
                    _positionUpdates[position].ProjPtsMin = 0

                  setPositionUpdates(_positionUpdates)
                }}/>
              </Col>
            </Row>
          )
        })
      }
    </Modal>
  )
}