import { Row, Col, Checkbox, Tooltip, Input } from 'antd'
import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'

import * as lineupActions from '../actions/lineup'
import { cache_settings } from '../utils/sport-settings'

const InputLabel = styled.div`
  font-weight: 600;
  font-size: 10px;
  display: inline-block;
`
const SettingsInput = styled(Input)`
  font-size: 16px;
  display: inline-block;
  .ant-input-group-addon {
    @media (max-width: 1280px) {
      font-size: 12px;
    }
  }
  input {
    font-size: 16px;
  }
`
const ClickableDiv = styled.div`
  cursor: pointer;
  &:hover {
    font-weight: 600;
  }
`
const StyledRow = styled(Row)`
  float: right;
  width: 220px;

  @media (max-width:767px) {
    float: left;
    margin-top: 15px
  }
`
const StyledHeading = styled.div`
  display: inline-block;
  margin-right: 5px;
  font-size: 16px;
`

class NumberOfLineupsBar extends Component {

  updateInputValue = (val, key) => {
    this.props.updateSettings( { site: this.props.site, slate: this.props.slate, [key]: val, sport: this.props.sport, counter: this.props.counter, season: this.props.season } )
  }

  getNumberOfLineupsAfterStart = () => {
    let numLus = 0
    Object.keys(this.props.afterStartLineups).forEach(contestID => {
      const elem = this.props.afterStartLineups[contestID]
      if (elem.active)
        numLus += elem.lineups.length
    })

    return numLus
  }

  render() {
    const permissions = this.props.subscription.permissions
    if (!permissions) return false
    const savedLineupsNumber = this.props.savedLineups.data ? this.props.savedLineups.data.length : 0
    const settings = this.props.settings.data

    return(
        <StyledRow>
          <Col lg={24}>
            {
              this.props.loggedin && settings.includeSaved ? (
                  <SettingsInput
                    type="number"
                    style={{width: '200px', fontSize: '16px', display: 'inline-block'}}
                    addonBefore={`${savedLineupsNumber} +`}
                    addonAfter={`= ${settings.numLUs}`}
                    value={settings.numLUs - savedLineupsNumber >= 0 ? settings.numLUs - savedLineupsNumber : 0}
                    key='numLUs'
                    onChange={evt => {
                      let _val = Number(evt.target.value) + savedLineupsNumber
                      if (_val < 0)
                        _val = 0
                      if (_val > permissions.opt.nfl.lineup_limit)
                        _val = permissions.opt.nfl.lineup_limit

                      this.updateInputValue(
                        _val,
                        'numLUs'
                      )
                    }}
                    onBlur={() => {
                      if (settings.numLUs - savedLineupsNumber < 0)
                        this.updateInputValue(
                          savedLineupsNumber,
                          'numLUs'
                        )
                    }}
                  />
              ) : (
                <div>
                  <StyledHeading style={{display: 'inline-block'}}>Lineups</StyledHeading>
                  <SettingsInput
                    type="number"
                    style={{fontSize: '16px', width: '82px', display: 'inline-block'}}
                    value={this.props.afterStartActivated ? this.getNumberOfLineupsAfterStart() : settings.numLUs}
                    key='numLUs'
                    onChange={evt => {
                      let _val = Number(evt.target.value)
                      if (_val < 0)
                        _val = 0
                      if (_val > permissions.opt.nfl.lineup_limit)
                        _val = permissions.opt.nfl.lineup_limit

                      this.updateInputValue(
                        _val,
                        'numLUs'
                      )
                    }}
                    disabled={this.props.afterStartActivated || this.props.disabled}
                  />
                </div>
              )
            }
          </Col>
        </StyledRow>
    )
  }
}

export default connect(
  state => ({
    settings: state.lineup.settings,
    subscription: state.account.subscription,
    loggedin: state.auth.loggedin,
    savedLineups: state.lineup.savedLineups,
    afterStartLineups: state.afterStart.afterStartLineups.data,
    afterStartActivated: state.afterStart.activated,
  }),
  {
    updateSettings: lineupActions.updateSettings,
  }
)(NumberOfLineupsBar)
