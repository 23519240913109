import { Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { checkPartnerToken } from '../actions/auth'

const Initializer = ({ ...props }) => {
  const params = new URLSearchParams(window.location.search)
  let token = params.get('hash')

  useEffect(() => {
    if (token) {
      props.checkPartnerToken(token)
    }
  }, [])
  // const [username, setusername] = useState()

  // // listen for username to come from parent
  // window.addEventListener('message', function(event) {
  //   if (event.data.target === 'nfl-optimizer') {
  //     if (event.data.data && event.data.data.user) {
  //       setusername(event.data.data.user)
  //     }
  //   }
  // });


  if (!token || props.auth.loadingCheck) {
    return (
      <div style={{marginTop: '400px', textAlign: 'center'}}>
        <Spin />
      </div>
    )
  }

  return (
    <div>{ props.children }</div>
  )
}    


export default connect(
  state => ({
    auth: state.auth
  }),
  {
    checkPartnerToken: checkPartnerToken
  }
)(Initializer)
